import React, { useEffect, useState } from "react";
import Select from "react-select";
import fetchApiData from "../../service/apiUtils";

const LocationDropdown = ({ custId, onSelectLocation }) => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (!custId) return;

    // Fetch locations based on selected customer ID
    fetchApiData(`/locations?custId=${custId}`)
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.customers);
        }
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  }, [custId]);

  return (
    <div className="locationDropdown">
      <Select
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: "#333",
            color: "#FFF",
            borderRadius: "20px",
            width: "20%",
            fontSize: "14px",
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: "#333",
            width: "100%",
            color: "white",
            maxHeight: "200px", // Enables scrolling if needed
            overflowY: "auto",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#fff" : "#333",
            color: "#ffff",
          }),
        }}
        options={locations.map((loc) => ({
          value: loc.ROWID,
          label: loc.locName,
        }))}
        onChange={(selectedOption) => {
          setSelectedLocation(selectedOption);
          onSelectLocation(selectedOption);
        }}
        value={selectedLocation}
        placeholder="Select a location..."
        isSearchable
        menuShouldScrollIntoView={true} // Ensures dropdown auto-scrolls
        menuPortalTarget={document.body} // Helps prevent dropdown clipping (optional)
      />
    </div>
  );
};

export default LocationDropdown;
