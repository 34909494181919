import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector} from "react-redux";
import logo from "../../assets/INDEX_LOGO.png";
import profile from "../../assets/Profile.png";
import settings from "../../assets/settings.png";
import w2hLogo from "../../assets/w2hLogo.png";
import "./head.css";
import { UAParser } from "ua-parser-js";
import moment  from "moment";
import axios from "axios";


const Head = function () {
  
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const username = useSelector((state)=>state.user.emailId);
  const getDeviceInfo = () => {
    const user_agent = navigator.userAgent;
    const parser = new UAParser(user_agent);
    const os = parser.getOS().name + " " + parser.getOS().version;  // Example: "Windows 10"
    const browser = parser.getBrowser().name + " " + parser.getBrowser().version;
    return [browser,os];

};
  
  const handleClick = async () => {
    
    const deviceInfo = getDeviceInfo(); 
    
    const currentTime = moment().tz("Asia/Kolkata").format('YYYY-MM-DD HH:mm:ss');
        
    let logs = {
      username:username,
      logout_time : currentTime,
      device_os :deviceInfo[1],
      device_browser:deviceInfo[0],
      description:"Successfully logged out",
    }   
    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_KEY_INDEX_DEV
          : process.env.REACT_APP_API_KEY_INDEX_PROD ,
    };
    let url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_INDEX_DEV + "logoutlogs"
        : process.env.REACT_APP_API_BASE_URL_INDEX_PROD + "logoutlogs";
    try{
      const response2 = await axios.post(url ,logs, { headers });  
      if(response2.status === 200)
        {
          localStorage.clear(); 
          sessionStorage.clear();
          navigate("/app") 
        }
        else{
          window.location.reload();
        }
    }
    catch(err)
    {
      
      console.log(err);
    }                    
  };
    
  
  const handleIconClick = () => {
    setDropdown(!dropdown);
  };
  const handleOptionClick = (option) => {
    if (option === "Logout") {
      document.getElementById("main-alert-box").style.display = "flex";
      setTimeout(handleClick, 3000);
      
    };
    setDropdown(false);
  };

  const handleSettingClick = () => {
    const url = window.location.href;
    if (url.includes("/setting")) {
      navigate("/app/dashboard");
    } else navigate("/app/setting");
  };
  return (
    <div className="ind-title">
      <div id="main-alert-box">
        <span className="loader"></span>
      </div>
      <div
        style={{
          position: "absolute",
          left: "0",
          zIndex: "15",
        }}
      >
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <div style={{verticalAlign:"middle"}}>  
          <img src={logo} width={35} style={{paddingLeft:"0.5rem"}}  alt="" />
          </div>
      <h2 style={{padding:"0.2rem"}}>W2H Index</h2>
        </div>
      </div>
      <div className="ind-profile">
        <img
          src={profile}
          style={{ height: "25px" }}
          alt=""
          onClick={handleIconClick}
        />
      </div>
      {dropdown && (
        <div className="ind-drop">
          <div onClick={() => handleOptionClick("Logout")}>Log Out</div>
        </div>
      )}
    </div>
  );
};
export default Head;
