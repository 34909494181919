import React, { useEffect, useState, useRef } from "react";

import logo from '../../assets/Agent-MOPTro.png';
import send from '../../assets/send.png';
import "./styles.css";
import fetchApiData from "../../service/apiUtils"; 
import CustomerDropdown from "./CustomerDropdown";
import LocationDropdown from "./LocationDropdown"; 
import VinDropdown from "./VinDropdown";
import VehicleInfo from "./VehicleInfo";
import VinSelector from "./VinSelector";
import WriteObservation from "./WriteObservation";
import EditObservation from "./EditObservation";
import ShowObservation from "./ShowObservation";



const ChatWithUs = () => {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]); // Chat messages
  const [suggestions, setSuggestions] = useState([]); // Quick reply suggestions
  const [vinList, setVinList] = useState([]); // VIN list
  const [vinSelected, setVinSelected] = useState(""); // Selected VIN
  const [currentFlow, setCurrentFlow] = useState(""); // Active flow (Vehicle Health or Customer Details)
  const chatHistoryRef = useRef(null); // Ref for auto-scroll
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const [selectedVin, setSelectedVin] = useState(null);
  const [showVinDropdown, setShowVinDropdown] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState(null);

  const handleSuggestionClick = (suggestion) => {
    setSuggestions([]); // Clear suggestions after selection
    setChatHistory((prevHistory) => [...prevHistory, { sender: "user", text: suggestion }]);

    if (suggestion === "Vehicle Health") {
      setCurrentFlow("Vehicle Health");
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "system", text: "Please select the VIN below." }
      ]);
      
    } else if (suggestion === "Customer Details") {
      setCurrentFlow("Customer Details");
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "system", text: "Please select the Customer Name below." }
      ]);
    }else if (suggestion === "FGQC Observations") {
      setCurrentFlow("FGQC Observations");
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "system", text: "Choose an option from below." }
        
      ]);
  setSuggestions(["Write Observations","Show Observations","Edit Observations"])
      
  }else if (suggestion === "Write Observations") {
    setCurrentFlow("Write Observations");
   
}
else if (suggestion === "Edit Observations") {
  setCurrentFlow("Edit Observations");
  // setChatHistory((prevHistory) => [
  //   ...prevHistory,
  //   { sender: "system", text: "Feature Coming soon..." }
  // ]);

} 
else if (suggestion === "Show Observations") {
  setCurrentFlow("Show Observations");

};}
  
  
    const handleVinSelect = async (e) => {
    const selectedVin = e.target.value;
    setVinSelected(selectedVin);
    localStorage.setItem("vin", selectedVin);
    setChatHistory((prevHistory) => [...prevHistory, { sender: "user", text: ` ${selectedVin}` }]);
    if (currentFlow === "Vehicle Health") {
        

        try {
            // Call API to fetch vehicle data
            const response = await fetchApiData(`/vehicleInfo?vin=${selectedVin}`);

            if (response.status === 200) {
                const vehicleData = response.data;

                // Generate vehicle status message
                const vehicleStatusMessage = (
                  <>
                      Vehicle is{" "}
                      <span className={vehicleData.vehicleHealth === "Online" ? "onlineStatus" : "offlineStatus"}>
                          {vehicleData.vehicleHealth === "Online" ? "ONLINE" : "OFFLINE"}
                      </span>
                      <br />
                      Last Pinged: {vehicleData.recordTimestamp ?? "N/A"}
                  </>
              );
              
                // Create the vehicle information table
                const vehicleTable = (
                    <div className="vehicle-info-container">
                        <table className="vehicleTable">
                            <thead>
                                <tr>
                                    <th colSpan="4" className="statusHeader">
                                        {vehicleStatusMessage}
                                    </th>
                                </tr>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Value</th>
                                    <th>Parameter</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><strong>Battery Voltage</strong></td>
                                    <td>{vehicleData.BatteryVoltage ?? "N/A"} V</td>
                                    <td><strong>Motor Temperature</strong></td>
                                    <td>{vehicleData.MotorTemperature ?? "N/A"} °C</td>
                                </tr>
                                <tr>
                                    <td><strong>Battery Percentage</strong></td>
                                    <td>{vehicleData.BatteryPercentage ?? "N/A"} %</td>
                                    <td><strong>Controller Temperature</strong></td>
                                    <td>{vehicleData.ControllerTemperature ?? "N/A"} °C</td>
                                </tr>
                                <tr>
                                    <td><strong>Mobile Percentage</strong></td>
                                    <td>{vehicleData.MobilePercentage ?? "N/A"} %</td>
                                    <td><strong>Vehicle Error Counts</strong></td>
                                    <td>{vehicleData.VehicleErrorCounts ?? "N/A"}</td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="statusMessage">
                            Want to know more about vehicle status? Hit the button below.
                        </p>

                        <button 
                            className="suggestionButton" 
                            onClick={(e) => handleStatusClick(e.target.value)} >
                            More Info..
                        </button>
                    </div>
                );

                // Add table to chat history
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    { sender: "system", text: vehicleTable },
                ]);
            } else {
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    { sender: "system", text: "Oops! 🤷‍♂️ This vehicle was never pinged." }
                ]);
            }
        } catch (error) {
            setChatHistory((prevHistory) => [
                ...prevHistory,
                { sender: "system", text: "Oops!🤷‍♂️ No information available for this Vehicle." }
            ]);
        } 
    }
    setVinList([]); // Clear VIN list after selection
};
  
const getStatusColor = (status) => {
  return status.toLowerCase() === "on" ? "greenText" : "redText";
};

const handleStatusClick = async (status) => {
  const selectedVin = vinSelected || chatHistory.find(msg => msg.sender === "user" && msg.text)?.text?.trim();
  const vin = localStorage.getItem("vin");

  if (!selectedVin) return; // Ensure there's always a VIN


  try {
      const response = await fetchApiData(`/vehicleMainStatus?vin=${vin}`);

      if (response.status === 200) {
          const { mainStatus, nativeStatus, oppositeStatus, recordTimestamp } = response.data;

          const statusTable = (
              <table className="statusTable">
                  <thead>
                      {/* Vehicle Status & Last Pinged Message */}
                      <tr>
                        <th colSpan="6" className="statusHeader">
                         Vehicle is {" "}
                         <span className={mainStatus.vehicleHealth === "Online" ? "onlineStatus" : "offlineStatus"}>
                         {(mainStatus.vehicleHealth ?? "N/A").toUpperCase()}</span>
                         <br /> Last Pinged: {recordTimestamp ?? "N/A"}
                       </th> 
                     </tr>
                      <tr>
                          <th>Vehicle Status</th>
                          <th>Value</th>
                          <th>Native Side</th>
                          <th>Value</th>
                          <th>Opposite Side</th>
                          <th>Value</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>Ignition Status</td>
                          <td className={getStatusColor(mainStatus.ignitionStatus)}>{mainStatus.ignitionStatus ?? "N/A"}</td>
                          <td>Push Status</td>
                          <td className={getStatusColor(nativeStatus.pushStatus)}>{nativeStatus.pushStatus ?? "N/A"}</td>
                          <td>Push Status</td>
                          <td className={getStatusColor(oppositeStatus.pushStatus)}>{oppositeStatus.pushStatus ?? "N/A"}</td>
                      </tr>
                      <tr>
                          <td>Emergency Status</td>
                          <td className={getStatusColor(mainStatus.emergencyStatus)}>{mainStatus.emergencyStatus ?? "N/A"}</td>
                          <td>Brake Status</td>
                          <td className={getStatusColor(nativeStatus.brakeStatus)}>{nativeStatus.brakeStatus ?? "N/A"}</td>
                          <td>Brake Status</td>
                          <td className={getStatusColor(oppositeStatus.brakeStatus)}>{oppositeStatus.brakeStatus ?? "N/A"}</td>
                      </tr>
                      <tr>
                          <td>Brake Status</td>
                          <td className={getStatusColor(mainStatus.brakeStatus)}>{mainStatus.brakeStatus ?? "N/A"}</td>
                          <td>E-Status</td>
                          <td className={getStatusColor(nativeStatus.eStatus)}>{nativeStatus.eStatus ?? "N/A"}</td>
                          <td>E-Status</td>
                          <td className={getStatusColor(oppositeStatus.eStatus)}>{oppositeStatus.eStatus ?? "N/A"}</td>
                      </tr>
                      <tr>
                          <td></td><td></td>
                          <td>Motor Status</td>
                          <td className={getStatusColor(nativeStatus.motorStatus)}>{nativeStatus.motorStatus ?? "N/A"}</td>
                          <td>Motor Status</td>
                          <td className={getStatusColor(oppositeStatus.motorStatus)}>{oppositeStatus.motorStatus ?? "N/A"}</td>
                      </tr>
                      <tr>
                          <td></td><td></td>
                          <td>Throttle Status</td>
                          <td className={getStatusColor(nativeStatus.throttleStatus)}>{nativeStatus.throttleStatus ?? "N/A"}</td>
                          <td>Throttle Status</td>
                          <td className={getStatusColor(oppositeStatus.throttleStatus)}>{oppositeStatus.throttleStatus ?? "N/A"}</td>
                      </tr>
                  </tbody>
              </table>
          );

          setChatHistory((prevHistory) => [
              ...prevHistory,
              { sender: "system", text: statusTable }
          ]);
      } else {
          setChatHistory((prevHistory) => [
              ...prevHistory,
              { sender: "system", text: "Oops!🤷‍♂️ This vehicle was never pinged..." }
          ]);
      }

      // Closing message
      setChatHistory((prevHistory) => [
          ...prevHistory,
          { sender: "system", text: "Thanks for chatting with Agent MOPTro! Until next time! 👋😊" }
      ]);
  } catch (error) {
      setChatHistory((prevHistory) => [
          ...prevHistory,
          { sender: "system", text: "Oops!🤷‍♂️ No information available for this Vehicle " }
      ]);
  } 
};

  // Handle user input change
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  // Handle "Enter" key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSend();
  };

  // Function to send user message
const handleSend = () => {
  if (!userInput) return;

  setChatHistory((prevHistory) => {
    // If chat history is empty, set greeting before adding user message
    if (prevHistory.length === 0) {
      return [
        { sender: "system", text: "Hi!👋 I'm Agent MOPTro 🤖" },
        { sender: "system", text: "How can I assist you today?" },
        { sender: "user", text: userInput }
      ];
    }
    return [...prevHistory, { sender: "user", text: userInput }];
  });

  // setSuggestions(["Vehicle Health", "Customer Details","FGQC Observations"]);
  setUserInput(""); // Clear input field
};

const reset = () => {
  setChatHistory([
    { sender: "system", text: "Hi!👋 I'm Agent MOPTro 🤖" },
    { sender: "system", text: "How can I assist you today?" },
  ]);
  setSuggestions(["Vehicle Health", "Customer Details" , "FGQC Observations"]);
  setVinSelected(null);
  setCurrentFlow("");
  setSelectedCustomer(null);
  setSelectedLocation(null);
  setShowLocationDropdown(false);
  setVehicleInfo(null);
  setSelectedVin(null);
};

// Auto-scroll when chat history updates
useEffect(() => {
  if (chatHistoryRef.current) {
    chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
  }
}, [chatHistory]);

  // Initialize chat on mount
  useEffect(() => {
    reset();
  }, []);

  return (
    <>  
      <div className="container">
        <div className="chatBox">
          <div className="chatHeader">
            <img src={logo} className="agentLogo" alt="logo" />
            <h2>Agent MOPTro</h2>
          </div>
          <div className="chatHistory" ref={chatHistoryRef}>
            {chatHistory.map((chat, index) => (
              <div key={index} className={`chatMessage ${chat.sender}`}>

              <div className={`chatBubble ${chat.sender === "user" ? "userBubble" : "systemBubble"}`}>

        
                  {typeof chat.text === "string" ? <p>{chat.text}</p> :chat.text}
                </div>
              </div>
            ))}


            {/* VIN Dropdown for Vehicle Health */}
            <VinSelector 
            currentFlow={currentFlow} 
            handleVinSelect={handleVinSelect} 
            vinSelected={vinSelected} />


           {/* Customer Dropdown for Customer Details */}
           {currentFlow === "Customer Details" && !selectedCustomer && (
           <div className="customerSelection">
           <CustomerDropdown 
           onSelectCustomer={(selected) => {
            setSelectedCustomer(selected); // Save selected customer
            setShowLocationDropdown(true); // Show location dropdown
             setChatHistory((prev) => [
          ...prev,
          { sender: "user", text: selected.label }, // Show selected customer in chat
          { sender: "bot", text: `Please select the Customer Location below for "${selected.label}"` }, // Show instruction message
        ]);
      }}
    />
  </div>
)}

{/* Instruction message and Location Dropdown */}
{showLocationDropdown && selectedCustomer && (
  <>
    <div className="botMessage">
      
    </div>
    <div className="locationSelection">
      <LocationDropdown
        custId={selectedCustomer.value}
        onSelectLocation={(selected) => {
          setSelectedLocation(selected.label); // Save selected location
          setShowLocationDropdown(false); // Hide location dropdown
          setShowVinDropdown(true); // Show VIN dropdown
          setChatHistory((prev) => [
            ...prev,
            { sender: "user", text: selected.label }, // Show selected location in chat
            { sender: "bot", text: `Please select VIN below for "${selected.label}" for furthur Health check of the vehicle` }, // Show fetching message
          ]);
        }}
      />
    </div>
  </>
)}
{/* fgqc flows */}
{
  currentFlow === "Write Observations" && <WriteObservation currentFlow={currentFlow} setChatHistory={setChatHistory}/>
}
{
  currentFlow === "Edit Observations" && <EditObservation currentFlow={currentFlow} setChatHistory={setChatHistory}/>
}
{
  currentFlow === "Show Observations" && <ShowObservation currentFlow={currentFlow} setChatHistory={setChatHistory}/>
}

{/* VIN Dropdown - Appears After Selecting Location */}
{showVinDropdown && selectedLocation && (
  <div className="vinSelection">
    <VinDropdown
      customerLocation={selectedLocation}
      onSelectVin={(vin) => {
        setSelectedVin(vin);
        setShowVinDropdown(false); // Hide VIN dropdown after selection
        setChatHistory((prev) => [
          ...prev,
          { sender: "user", text: vin }, // Show selected VIN in chat
        ]);
      }}
    />
  </div>
)}

{/* Vehicle Information - Appears After VIN Selection */}
{selectedVin && (
  <VehicleInfo vin={selectedVin} setChatHistory={setChatHistory}/>
)}
            {/* Suggestions */}
            {suggestions.length > 0 && (
              <div className="suggestions">
                {suggestions.map((suggestion, index) => (
                  <button key={index} onClick={() => handleSuggestionClick(suggestion)} className="suggestionButton">
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="inputArea">
            <input
              type="text"
              value={userInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
              className="input"
            />
            <button onClick={handleSend} className="sendButton">
              <img src={send} className="send" alt="send-icon" />
            </button>
            <button onClick={reset} className="sendButton">
              Reset Chat
            </button>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default ChatWithUs;         