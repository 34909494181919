import React, { useEffect, useState } from "react";
import Select from "react-select";
import fetchApiData from "../../service/apiUtils"; // Import the API utility


const CustomerDropdown = ({ onSelectCustomer }) => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const vinCustomStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "#FFF",
      borderRadius: "20px",
      width: "20%",
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      width: "100%",
      color: "white",
      maxHeight: "200px", // Enables scrolling if needed
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#fff" : "#333",
      color: "#ffff",
    }),
  }
  useEffect(() => {
    // Fetch customers using fetchApiData utility function
    fetchApiData("/customers")
      .then((response) => {
        if (response.status === 200) {
          setCustomers(response.customers);
        }
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  }, []);

  return (
    <div className="customerDropdown">
      <Select
        styles={vinCustomStyles}
        options={customers.map((cust) => ({
          value: cust.ROWID,
          label: cust.name,
        }))}
        onChange={(selectedOption) => {
          setSelectedCustomer(selectedOption);
          onSelectCustomer(selectedOption); // Pass selected customer to parent component
        }}
        value={selectedCustomer}
        placeholder="Select a customer..."
        isSearchable
        menuShouldScrollIntoView={true} // Ensures dropdown auto-scrolls into view
        menuPortalTarget={document.body} // Helps prevent clipping issues (optional)
      />
    </div>
  );
};

export default CustomerDropdown;
