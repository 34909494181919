import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import Loader from "../../Components/Loader/Loader";
import fetchApiData from "../../service/apiUtils";
import './showObservations.css'
import FGQCObservationsTable from "./FGQCObservationsTable";

const ShowObservation = ({ setChatHistory }) => {
  const vinList = useSelector((state) => state.vin.vin); // Extract directly from redux
  const [showObsVinList, setShowObsVinList] = useState([]);
  const [showObsVinSelected, setShowObsVinSelected] = useState(null);
  const [loader, setLoader] = useState(false);
  const fgqcCustomStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "#FFF",
      borderRadius: "20px",
      width: "100%",
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#fff" : "#333",
      color: "#fff",
    }),
  }
  useEffect(() => {
    setChatHistory((prev) => [...prev, { sender: "system", text: "Choose a vin number from below." }]);
    setShowObsVinList(vinList);
  }, []);

  const handleShowObsVinSelect = async (selectedOption) => 
  {
    if (!selectedOption) return;

    const selectedVin = selectedOption.value;
    setShowObsVinSelected(selectedVin);
    setChatHistory((prev) => [...prev, { sender: "user", text: selectedVin }]);

    try 
    {
      setLoader(true);
    
      const response = await fetchApiData(`slObservations?vin=${selectedVin}`);
      
      
      if (response.status === 200) 
      { 
        let ObsArray = response.data;
      let length = ObsArray.length;
      let observationData = new Array(length).fill("");
      ObsArray.map((obj)=>observationData[obj.serialNumber - 1]=obj.observations)
       
        

        const observationTable = <FGQCObservationsTable observations={observationData} selectedVin={selectedVin} />;

        setChatHistory((prev) => [...prev, { sender: "system", text: observationTable }]);
      } 
      else 
      {
        setChatHistory((prev) => [...prev, { sender: "system", text: "No observations found." }]);
      }
    } 
    catch (error) 
    {
      setChatHistory((prev) => [...prev, { sender: "system", text: "Oops! 🤷‍♂️ No information available for this Vehicle." }]);
    } 
    finally 
    {
      setLoader(false);
      setShowObsVinList([]); // Clearing list post selection
      setChatHistory((prev) => [...prev,{ sender: "system", text: "Reset Chat to go back to Menu!👋😊" }]);
    }
  };

  return (
    <>
      {loader && <Loader />}

      <div className="vinDropdown">
        {showObsVinList?.length > 0 && (
          <Select
            styles={fgqcCustomStyles}
            options={showObsVinList.map((vin) => ({ value: vin, label: vin }))}
            onChange={handleShowObsVinSelect}
            value={showObsVinSelected ? { value: showObsVinSelected, label: showObsVinSelected } : null}
            placeholder="Search or select a VIN..."
            isSearchable
          />
        )}
      </div>
    </>
  );
};

export default ShowObservation;
