import React from "react";

import "./footer.css";

const footer = function () {
  return (
    <div className="foot">
      <div className="footer-text">
        ©2025, Greendzine Technologies, Pvt. Ltd. All rights reserved{" "}
      </div>
    </div>
  );
};

export default footer;
