import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Select from "react-select";
import Loader from "../../Components/Loader/Loader";
import './editObservation.css';
import EditableTable from './EditableTable';
import fetchApiData from "../../service/apiUtils";

const EditObservation = ({ setChatHistory }) => {
  // State to store the list of VIN numbers from Redux store
  const [editObsVinList, setEditObsVinList] = useState(useSelector((state) => state.vin.vin));
  
  // State to store the selected VIN number
  const [editObsVinSelected, setEditObsVinSelected] = useState(null);
  
  // State to manage the loading indicator
  const [loader, setLoader] = useState(false);
  
  // Custom styles for the Select component
  const fgqcCustomStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "#FFF",
      borderRadius: "20px",
      width: "100%",
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#fff" : "#333",
      color: "#fff",
    }),
  };

  // Effect to add an initial message to chat history
  useEffect(() => {
    setChatHistory((prev) => [...prev, { sender: "system", text: "Choose a VIN number from below." }]);
  }, []);

  // Handler function for selecting a VIN number
  const handleEditObsVinSelect = async (selectedOption) => {
    if (!selectedOption) return;
    
    const selectedVin = selectedOption.value;
    setEditObsVinSelected(selectedVin);
    
    // Update chat history with the selected VIN
    setChatHistory((prev) => [...prev, { sender: "user", text: selectedVin }]);
    
    try {
      setLoader(true);
      
      // Fetch observation data based on the selected VIN
      const response = await fetchApiData(`slObservations?vin=${selectedVin}`);
      
      let ObsArray = response.data;
      let length = ObsArray.length;
      let observationData = new Array(length).fill("");
      ObsArray.map((obj) => observationData[obj.serialNumber - 1] = obj.observations);
      
      if (response.status === 200) {
        // Render EditableTable component with fetched observations
        const edittable = <EditableTable
          finalObservations={observationData}
          editObsVinSelected={selectedOption.value}
          setChatHistory={setChatHistory}
        />;
        
        setChatHistory((prev) => [...prev, { sender: "system", text: edittable }]);
      } else {
        // No observations found message
        setChatHistory((prev) => [...prev, { sender: "system", text: "No observations found." }]);
        setChatHistory((prev) => [...prev, { sender: "system", text: "Reset Chat to go back to Menu!👋😊" }]);
      }
    } catch (error) {
      // Handle error when fetching data fails
      setChatHistory((prev) => [...prev, { sender: "system", text: "Oops! 🤷‍♂️ No information available for this Vehicle." }]);
    } finally {
      setLoader(false);
      setEditObsVinList([]); // Clear VIN list post selection
    }
  };

  return (
    <>
      {loader && <Loader />} {/* Display loader while fetching data */}
      <div className="vinDropdown">
        {editObsVinList?.length > 0 && (
          <Select
            styles={fgqcCustomStyles}
            options={editObsVinList.map((vin) => ({ value: vin, label: vin }))}
            onChange={handleEditObsVinSelect}
            value={editObsVinSelected ? { value: editObsVinSelected, label: editObsVinSelected } : null}
            placeholder="Search or select a VIN..."
            isSearchable
          />
        )}
      </div>
    </>
  );
};

export default EditObservation;
