import React from 'react';
import navData from "../../service/navigation"; 
import SideBody from "../../Components/SideNavBar/SideNavBar";
import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import ChatWithUs from './ChatWithUs';
import lodash from 'lodash';

//last updated PCR-676
const ChatPage = () => {
  // Create a deep clone of navData to modify it
  let fields = lodash.cloneDeep(navData);
  fields[5].enable = true;
  fields[5].class = true;

  return (
    <div style={{ display: 'flex' }}>
      <Head/>
      {/* Sidebar */}
      <div style={{ width: "10%" }} className="sidebar">
        <ul className="list">
          {fields.map((field, idx) => (
            <SideBody
              key={idx}
              path={field.path}
              img={field.img}
              class={field.class}
              name={field.name}
              subheading={field.subheading}
              enable={field.enable}
            />
          ))}
        </ul>
      </div>

      {/* Chat Component */}
      <div style={{ flex: 1 }}>
        <ChatWithUs />
      </div>
      <Footer/>
    </div>
  );
};

export default ChatPage;
