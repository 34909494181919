import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceTicketsBody from './ServiceTicketsBody';
import Footer from "../../Components/Footer/Footer";
import SideBody from "../../Components/SideNavBar/SideNavBar";
import navData from "../../service/navigation";
import loadash from "lodash";
import Head from "../../Components/Head/Head";

const ServiceTickets = () => {
  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const loggedInUser = localStorage.getItem("authenticated");

  useEffect(() => {
    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);
  useEffect(() => {
    if (loggedInUser === "true") {
      navigate("/app/service_tickets_logs");
    } else {
      navigate("/app");
    }
  }, [authenticated, navigate]);

  let fields = loadash.cloneDeep(navData);
  fields[7].class = true;
  
  return (
    <>
    <Head />
    <div style={{ width: "10%" }} className="sidebar">
      <ul className="list">
        {fields.map((field, idx) => (
          <SideBody
            key={idx}
            path={field.path}
            img={field.img}
            class={field.class}
            name={field.name}
            subheading={field.subheading}
          />
        ))}
      </ul>
    </div>
    <ServiceTicketsBody />
    <Footer />
  </>
  );
};

export default ServiceTickets;