import React from "react";

const OptionsButtonTable=({handleSubmitObservations,setShowInputField})=>{
    return(<table ><tbody>
        <tr>
        <td colSpan="2" className="fgqc-heading">
            <b>Do you want to submit or add more?</b>
        </td>
        </tr>
    <tr>
    <td>  <button className="write-submit-btn" onClick={handleSubmitObservations}>
       Submit Observations
     </button></td>
    <td>  <button className="write-submit-btn" onClick={()=>{
      setShowInputField(true)
     }}>
       Add Observations
      </button></td>
    </tr>
       </tbody>
  
    </table>);
}
export default OptionsButtonTable;