import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import Loader from "../../Components/Loader/Loader";
import "./writeObservations.css"; // Add CSS for styling
import axios from "axios";
import OptionsButtonTable from './OptionsButtonTable';
import AddedObservationsTable from './AddedObservationsTable';

const WriteObservation = ({ setChatHistory }) => {
  
  const [writeObsVinList, setWriteObsVinList] = useState(useSelector((state) => state.vin.vin));
  const [writeObsVinSelected, setWriteObsVinSelected] = useState(null);
  const [observations, setObservations] = useState([]);
  const [observationInput, setObservationInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [showInputField , setShowInputField]=useState(false);
  const fgqcCustomStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "#FFF",
      borderRadius: "20px",
      width: "100%",
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#fff" : "#333",
      color: "#fff",
    }),
  }
  useEffect(() => {
    setChatHistory((prev) => [...prev, { sender: "system", text: "Choose a VIN number from below." }]);
    
  },[]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission default behavior
      setShowInputField(false);
      handleSend(event);
    }
  };
  const handleWriteObsVinSelect = (selectedOption) => {
    if (!selectedOption) return;
    setWriteObsVinSelected(selectedOption.value);
    setChatHistory((prev) => [...prev, { sender: "user", text: selectedOption.value }]);
    setWriteObsVinList([]);
    setChatHistory((prev) => [...prev, { sender: "system", text: `Write down FGQC observations for ${selectedOption.value}.` }]);
    setShowInputField(true);
  };
  const handleSend = () => {
    
    if (observationInput.trim() !== "") {
       const buttonTable = (<OptionsButtonTable handleSubmitObservations={handleSubmitObservations} setShowInputField={setShowInputField}/>);
      setObservations((prev) => [...prev, observationInput.trim()]);
      setObservationInput(""); // Clearing input after adding to observation Array.
      setChatHistory((prev) => [...prev, { sender: "user", text: `${observationInput.trim()}` }]);
      setChatHistory((prev) => [...prev, { sender: "system", text: buttonTable }]);  
    }
    else{
      return
    }
  };
  const handleObservationInputChange = (e) => {
    setObservationInput(e.target.value);
  };

  const handleSubmitObservations = async (event) => {
   
    setChatHistory((prev) => [...prev, { sender: "user", text: event.target.innerText }]);

    if (!writeObsVinSelected || (observationInput.trim() === "" && observations.length === 0)) {
        return;
    }

    // Ensuring last observation is added before submitting
    const finalObservations = [...observations];
    if (observationInput.trim() !== "") {
        finalObservations.push(observationInput.trim());
    }

    const addedObservations = (
        <AddedObservationsTable finalObservations={finalObservations} writeObsVinSelected={writeObsVinSelected} />
    );

    try {
        setLoader(true);
        const body = { vin: writeObsVinSelected, observations: finalObservations };
        const headers = {
            "Content-Type": "application/json",
            ZCFKEY: process.env.REACT_APP_ENV === "DEVELOPMENT"
                ? process.env.REACT_APP_API_KEY_DEV
                : process.env.REACT_APP_API_KEY_PROD,
        };
        const url = process.env.REACT_APP_ENV === "DEVELOPMENT"
            ? process.env.REACT_APP_API_BASE_URL_DEV + "addObservations"
            : process.env.REACT_APP_API_BASE_URL_PROD + "addObservations";

        const response = await axios.post(url, body, { headers });
        setShowInputField(false);

        if (response.status === 201) {
            setChatHistory((prev) => [...prev, { sender: "system", text: addedObservations }]);
            setObservations([]); // Clear observations array
            setObservationInput(""); // Clear input field
        } else {
            throw new Error("Failed to add observations");
        }
    } catch (error) {
        setChatHistory((prev) => [...prev, { sender: "system", text: "Error adding observations: " + error.message }]);
    } finally {
        setLoader(false);
        setWriteObsVinList([]); // Clear list after selection
        setChatHistory((prev) => [...prev, { sender: "system", text: "Reset Chat to go back to Menu! 👋😊" }]);
    }
};


  return (
    <div className="write-observation-container">
      {loader && <Loader />}

      <div className="vinDropdown">
        {writeObsVinList?.length > 0 && (
          <Select
            styles={fgqcCustomStyles}
            options={writeObsVinList.map((vin) => ({ value: vin, label: vin }))}
            onChange={handleWriteObsVinSelect}
            value={writeObsVinSelected ? { value: writeObsVinSelected, label: writeObsVinSelected } : null}
            placeholder="Search or select a VIN..."
            isSearchable
            
          />
        )}
      </div>

     {showInputField &&  <div className="observation-input-container">
        <textarea
          className="observation-input"
          value={observationInput}
          onChange={handleObservationInputChange}
          placeholder="Enter an observation..."
          onKeyDown={handleKeyDown}
        />
      
      </div>
      
  }

</div>
  );
};

export default WriteObservation;
