import React from "react";

const AddedObservationsTable = ({finalObservations,writeObsVinSelected})=>{
    return(<table className="observationTable">
        <thead>
            <tr>
                <td colSpan="2" className="fgqc-heading">
                    <b>Successfully added observations for {writeObsVinSelected}! ✅</b>
                </td>
            </tr>
            <tr>
                <th>SL.No.</th>
                <th className="thObservations">Observations</th>
            </tr>
        </thead>
        <tbody>
            {finalObservations.map((observation, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="observationColumn">{observation}</td>
                </tr>
            ))}
        </tbody>
    </table>);
}
export default AddedObservationsTable;