import React, { useEffect, useState } from "react";
import fetchApiData from "../../service/apiUtils"; // Utility for API calls

const VehicleInfo = ({ vin, setChatHistory }) => {
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!vin) return; // Only fetch if VIN is available

    setLoading(true);
    setError(null);

    fetchApiData(`/vehicleInfo?vin=${vin}`)
      .then((response) => {
        if (response.status === 200) {
          setVehicleInfo(response.data);
          const isOnline = response.data.vehicleHealth === "Online";
          
          const vehicleStatusMessage = (
            <div>
    Vehicle is{" "}
    <span className={isOnline ? "greenText" : "redText"}>
      {isOnline ? "ONLINE" : "OFFLINE"}
    </span>
    <br />
    Last Pinged: {response.data.recordTimestamp ?? "N/A"}
  </div>
          );

          const vehicleTable = (
            <div className="vehicle-info-container">
              <table className="vehicleTable">
                <thead>
                  <tr>
                    <th colSpan="4" className="statusHeader">{vehicleStatusMessage}</th>
                  </tr>
                  <tr>
                    <th>Parameter</th>
                    <th>Value</th>
                    <th>Parameter</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Battery Voltage</td>
                    <td>{response.data.BatteryVoltage ?? "N/A"} V</td>
                    <td>Motor Temperature</td>
                    <td>{response.data.MotorTemperature ?? "N/A"} °C</td>
                  </tr>
                  <tr>
                    <td>Battery Percentage</td>
                    <td>{response.data.BatteryPercentage ?? "N/A"} %</td>
                    <td>Controller Temperature</td>
                    <td>{response.data.ControllerTemperature ?? "N/A"} °C</td>
                  </tr>
                  <tr>
                    <td>Mobile Percentage</td>
                    <td>{response.data.MobilePercentage ?? "N/A"} %</td>
                    <td>Vehicle Error Counts</td>
                    <td>{response.data.VehicleErrorCounts ?? "N/A"}</td>
                  </tr>
                </tbody>
              </table>
              <p className="more-info-text">Want to know more about vehicle status? Hit the button below.</p>
              
              <button className="suggestionButton" onClick={fetchMoreInfo}>More Info..</button>
            </div>
          );

          setChatHistory((prevHistory) => [
            ...prevHistory,
            { sender: "system", text: vehicleTable },
          ]);
        } else {
          setError(response.message);
          setChatHistory((prevHistory) => [
            ...prevHistory,
            { sender: "system", text: "Oops! 🤷‍♂️ This vehicle was never pinged." }
          ]);
        }
      })
      .catch(() => {
        setError("Failed to fetch vehicle information.");
        setChatHistory((prevHistory) => [
          ...prevHistory,
          { sender: "system", text: "Oops!🤷‍♂️ No information available for this Vehicle." }
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vin]);

  const fetchMoreInfo = async () => {
    try {
      const response = await fetchApiData(`/vehicleMainStatus?vin=${vin}`);
      if (response.status === 200) {
        const { mainStatus, nativeStatus, oppositeStatus, recordTimestamp } = response.data;
  
        const getStatusColor = (status) => {
          return status?.toLowerCase() === "on" ? "greenText" : "redText";
        };
  
        const vehicleHealthStatus = mainStatus.vehicleHealth ?? "N/A";
        const vehicleHealthColor = vehicleHealthStatus.toLowerCase() === "online" ? "greenText" : "redText";
  
        const statusTable = (
          <table className="statusTable">
            <thead>
            <tr>
            <th colSpan="6" className="statusHeader">
              Vehicle is <span className={vehicleHealthColor}>{vehicleHealthStatus.toUpperCase()}</span>
              <br />
              <span className="lastPinged">Last Pinged: {recordTimestamp ?? "N/A"}</span>
            </th>
             </tr>

              <tr>
                <th>Vehicle Status</th>
                <th>Value</th>
                <th>Native Side</th>
                <th>Value</th>
                <th>Opposite Side</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ignition Status</td>
                <td className={getStatusColor(mainStatus.ignitionStatus)}>{mainStatus.ignitionStatus ?? "N/A"}</td>
                <td>Push Status</td>
                <td className={getStatusColor(nativeStatus.pushStatus)}>{nativeStatus.pushStatus ?? "N/A"}</td>
                <td>Push Status</td>
                <td className={getStatusColor(oppositeStatus.pushStatus)}>{oppositeStatus.pushStatus ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Emergency Status</td>
                <td className={getStatusColor(mainStatus.emergencyStatus)}>{mainStatus.emergencyStatus ?? "N/A"}</td>
                <td>Brake Status</td>
                <td className={getStatusColor(nativeStatus.brakeStatus)}>{nativeStatus.brakeStatus ?? "N/A"}</td>
                <td>Brake Status</td>
                <td className={getStatusColor(oppositeStatus.brakeStatus)}>{oppositeStatus.brakeStatus ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Brake Status</td>
                <td className={getStatusColor(mainStatus.brakeStatus)}>{mainStatus.brakeStatus ?? "N/A"}</td>
                <td>E-Status</td>
                <td className={getStatusColor(nativeStatus.eStatus)}>{nativeStatus.eStatus ?? "N/A"}</td>
                <td>E-Status</td>
                <td className={getStatusColor(oppositeStatus.eStatus)}>{oppositeStatus.eStatus ?? "N/A"}</td>
              </tr>
              <tr>
                <td></td><td></td>
                <td>Motor Status</td>
                <td className={getStatusColor(nativeStatus.motorStatus)}>{nativeStatus.motorStatus ?? "N/A"}</td>
                <td>Motor Status</td>
                <td className={getStatusColor(oppositeStatus.motorStatus)}>{oppositeStatus.motorStatus ?? "N/A"}</td>
              </tr>
              <tr>
                <td></td><td></td>
                <td>Throttle Status</td>
                <td className={getStatusColor(nativeStatus.throttleStatus)}>{nativeStatus.throttleStatus ?? "N/A"}</td>
                <td>Throttle Status</td>
                <td className={getStatusColor(oppositeStatus.throttleStatus)}>{oppositeStatus.throttleStatus ?? "N/A"}</td>
              </tr>
            </tbody>
          </table>
        );


        setChatHistory((prevHistory) => [
          ...prevHistory,
          { sender: "system", text: statusTable }
        ]);
        setChatHistory((prevHistory) => [
          ...prevHistory,
          { sender: "system", text: "Thanks for chatting with Agent MOPTro! Until next time! 👋😊" }
        ]);
      } else {
        setChatHistory((prevHistory) => [
          ...prevHistory,
          { sender: "system", text: "Oops!🤷‍♂️ This vehicle was never pinged..." }
        ]);
      }
    } catch (error) {
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "system", text: "Oops!🤷‍♂️ No information available for this Vehicle " }
      ]);
    }
  };

  return null;
};

export default VehicleInfo;
