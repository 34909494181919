import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import moment from "moment";
import fetchApiData from '../../service/apiUtils';
import './serviceTickets.css';
import Loader from "../../Components/Loader/Loader";
import customStyles from "./customStyles"
import {useSelector} from "react-redux";
import "moment-precise-range-plugin";


const ServiceTicketsBody = () => {
    const vinList = useSelector((state)=>state.vin.vin);
    const [selectedVin, setSelectedVin] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [serviceData, setServiceData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("NO DATA AVAILABLE")
    
    
    const fetchServiceHistoryDetails = async () => {
        if (!selectedVin || !selectedFilter) return;
        const endpoint = `serviceDetails?vin=${selectedVin}&filter=${selectedFilter}`;
        setLoader(true);
        try {
            const fetchedData = await fetchApiData(endpoint);
            
            if ( fetchedData.data.length) {
                
                setServiceData(fetchedData.data);
            }
            else if(fetchedData.status === 204 || (fetchedData.status === 200 && fetchedData.data.length === 0)){
                
                setMessage(`NO TICKETS WERE RAISED FOR THIS VEHICLE ${selectedFilter.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}.`)
                setServiceData([]);
            }
            else if(fetchedData.status === 404){
                setMessage("Error : Requested Resource Not Found")
            }
        } catch (error) {
            setMessage("Error occured while fetching data.")
            console.error("API Request Error:", error.status);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchServiceHistoryDetails();
    }, [selectedVin, selectedFilter]);

    const filterOptions = [
        { value: 'Today', label: 'Today' },
        { value: 'LastMonth', label: 'Last Month' },
        { value: 'LastYear', label: 'Last Year' },
    ];

    
    const calculateTimeDifference = (createdTime, resolvedTime,tn) => {
        if (resolvedTime === "Not Resolved") return "";
        const start = createdTime
    
        const end = moment(resolvedTime, "DD-MM-YYYY HH:mm:ss:SSS");
        
        const timeTaken = moment.preciseDiff(start, end, true);
        
        return [
            timeTaken.days > 0 ? `${timeTaken.days} days` : "",
            timeTaken.hours > 0 ? `${timeTaken.hours} hours` : "",
            timeTaken.minutes >= 0 && timeTaken.days === 0 ? `${timeTaken.minutes} minutes` : ""
        ].filter(Boolean).join(",");
    };

    return (
        
        <>            
            <div className="tickets-container rounded">
                {loader && <Loader />}
                <div className="container ">
                    {/* Filter Section */}
                    <div className="filter-container ">
                        <div>
                            <label className="text-lg">VIN:</label>
                            <Select
                              placeholder="Select a VIN"
                              onChange={setSelectedVin}
                              options={vinList?.map((vin) => ({ value: vin, label: vin }))}
                              styles={customStyles}
                              showSearch={!selectedVin?true:false} 
                              allowClear={true}
                              filterOption={(input, option) => 
                              option.label.toLowerCase().includes(input.toLowerCase())} 
                            
                            />
                        </div>
                        <div>
                            <label className="text-lg">Filter:</label>
                            <Select
                              placeholder="Select a Filter"
                              onChange={setSelectedFilter}
                              options={filterOptions}
                              
                            />
                        </div>
                    </div>
                
                    <div className="table-container">
                        {/* fixed headings */}
                        <div id="fixed_heading" className="service-history-heading">
                                <div className="nowrap-text" ></div>
                                <div className="nowrap-text">Ticket ID</div>
                                <div className="nowrap-text">Ticket Status</div>
                                <div className="nowrap-text">Description</div>
                                <div className="nowrap-text">Created Date</div>
                                <div className="nowrap-text">Resolved Date</div>
                                <div className="nowrap-text">Resolution Time</div>
                        </div>
                        {/* tickets contents in tabular format */}
                        <table className="service-table">
                            <tbody>
                                {serviceData?.length > 0 ? (
                                    serviceData?.map((item, index) => 
                                    {
                                        let createdTime = moment(item.createdTime, "DD-MM-YYYY HH:mm:ss:SSS");

                                        const  timeTaken  = calculateTimeDifference(createdTime, item.resolvedTime);
                                        return (
                                            <tr key={index}>
                                                <td >{item.ticketNumber}</td>
                                                <td >{item.statusType}</td>
                                                <td >{item.subject}</td>
                                                <td >{item.createdTime.split(" ")[0]}</td>
                                                <td style={item.resolvedTime === "Not Resolved" ? {  textAlign:'center',fontSize:'30px'} : {}}>{item.resolvedTime === "Not Resolved" ? "-" : item.resolvedTime.split(" ")[0]}</td>
                                                <td style={item.resolvedTime === "Not Resolved" ? {  textAlign:'center',fontSize:'30px' } : { textAlign: 'center' }}>{item.resolvedTime === "Not Resolved" ? "-" : createdTime > moment(item.resolvedTime, "DD-MM-YYYY HH:mm:ss:SSS") ?timeTaken.split(",").map((part, index) => (
                                                   <React.Fragment key={index}>
                                                     {index === 0?"-":""}{part}
                                                     {index !== timeTaken.split(",").length-1  && <br />}
                                                   </React.Fragment>
                                                 )):timeTaken.split(",")?.map((part, index) => (
                                                    <>
                                                      {part}
                                                      {index !== timeTaken.split(",").length-1  && <br />}
                                                    </>
                                                  ))}
                                                </td>
                                            </tr>
                                              );
                                    }    
                                    ) ) : (
                                            <>
                                                <tr></tr>
                                                <tr>
                                                    <td ></td>
                                                    <td ></td>
                                                    <td className='nowrap-text'>{message}</td>
                                                    <td ></td>
                                                    <td ></td>   
                                                </tr>
                                            </>
                                           )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default ServiceTicketsBody;