import dashboard from "../assets/dashboard.png";
// import assign from "../assets/assign.png";
import register from "../assets/registeration.png";
import customer from "../assets/customers.png";
import location from "../assets/location.png";
import loginId from "../assets/loginId.png";
import vehicles from "../assets/vehicles.png";
import ticket from "../assets/ticket.png";
import log from "../assets/log.png";
import vingenerator from "../assets/vingenerator.png"
import charts from "../assets/charts.png"
import report from "../assets/reports.png"
import setting from "../assets/setting.png"
import brakecontrol from "../assets/brakecontrol.png"
import access_logs from "../assets/access_logs.png"
import chat from "../assets/chat.png"

const navData = [
  {
    path: "/app/livedashboard",
    img: dashboard,
    class: false,
    enable: false,
    name: "Dashboard",
  },
  {
    path: "/app/dashboard",
    img: charts,
    class: false,
    enable: false,
    name: "Charts",
  },
  {
    path: "/app/parameter_report",
    img: report,
    class: false,
    enable: false,
    name: "Reports"
  },
  {
    path: "/app/logs/",
    img: log,
    class: false,
    enable: false,
    name: "Logs",
    isOpen: false,
  },
  {
    path: "/app/customer/register",
    img: setting,
    class: false,
    name: "Settings",
    enable: false,
    subheading: [
      {
        path: "/app/customer/register",
        img: customer,
        class: false,
        name: "Customer",
      },
      {
        path: "/app/location/registerLocation",
        img: location,
        class: false,
        name: "Location",
      },
      {
        path: "/app/customer/create-login",
        img: loginId,
        class: false,
        name: "LoginID",
      },
      {
        path: "/app/assign_customer",
        img: vehicles,
        class: false,
        name: "Vehicles",
      },
      {
        path: "/app/assign_pickerID",
        img: register,
        class: false,
        name: "Pickers",
      },
      {
        path: "/app/vin-generator",
        img: vingenerator,
        class: false,
        name: "Vin Generator",
      },
      {
        path: "/app/brake-control",
        img: brakecontrol,
        class: false,
        name: "Brake Control",
      },
    ],
  },
  {
    path: "/app/chatbot",
    img: chat,
    enable: false,
    class: false,
    name: "Agent MOPTro",
  },
  {
    path: "/app/access_logs",
    img: access_logs,
    class: false,
    enable: false,
    name: "Access Logs",
  },
  {
    path:"/app/service_tickets_logs",
    img : ticket ,
    class:false,
    enable:false,
    name:"Service Tickets"
  }

];

export default navData;