import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../Components/Footer/Footer";
import {setEmailId} from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import "./loginbody.css"; 
import logo from "../../assets/INDEX_LOGO.png";
import v1 from "../../assets/wasp.png";
import v2 from "../../assets/moptroB.png";
import v3 from "../../assets/snail.png";
import v4 from "../../assets/snailLite.png";
import w2h from "../../assets/w2hLogo.png";
import 'react-toastify/dist/ReactToastify.css'; // Import the default toast styles
import moment from 'moment-timezone';
import { UAParser } from 'ua-parser-js';  



const Body = function () {
  localStorage.clear();
  sessionStorage.clear();
  const [loader, setLoader] = useState(false);
  const [authenticated, setauthenticated] = useState(localStorage.getItem("authenticated") || false);
  const [username, setusername] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isLoginView, setIsLoginView] = useState(true);
  const [timer, setTimer] = useState(60);
  // const [locId , setLocId]= useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  let loggedIn = false;

  const getDeviceInfo = () => {
    const user_agent = navigator.userAgent;
    const parser = new UAParser(user_agent);
    const os = parser.getOS().name + " " + parser.getOS().version;  // Example: "Windows 10"
    const browser = parser.getBrowser().name + " " + parser.getBrowser().version; //Example: "Edge"
    return [browser,os];
  };
  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsResendDisabled(false); // Enable Resend OTP button after 30 seconds
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isOtpSent, timer]);


  const handleSubmit = async (e) => {
  
    setLoader(true);
    e.preventDefault();
    var body = { loginId: username, otp }; // Include OTP in the body when submitting
    const deviceInfo = getDeviceInfo();  // Get Device Info
   
    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_KEY_DEV
          : process.env.REACT_APP_API_KEY_PROD,
    };
    let url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV + "verifyOtp"
        : process.env.REACT_APP_API_BASE_URL_PROD + "verifyOtp";
   
    try {
      let logs = {};
      const response = await axios.post(url, body, { headers });
      const currentTime = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
      logs = {
        username:username,
        login_time : currentTime,
        device_os :deviceInfo[1],
        device_browser:deviceInfo[0],
        description:response.data.message,
      }  
   
      if (response.data.status === 200 )
      { 
      
        if(response.data.message === "Logging In Successful" ) {
            dispatch(setEmailId(username));  
            loggedIn = true;
            setauthenticated(true);
            localStorage.setItem("authenticated", true);
            navigate("/app/livedashboard");
        } 
        else {
            toast.error(response.data.message);
        }
      let url2 =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_INDEX_DEV + "loginlogs"
        : process.env.REACT_APP_API_BASE_URL_INDEX_PROD + "loginlogs";
      
    const response2 = await axios.post(url2 , logs , { headers:{"Content-Type": "application/json",
      ZCFKEY:
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_KEY_INDEX_DEV
          : process.env.REACT_APP_API_KEY_INDEX_PROD ,}
    });
      setLoader(false);

    }
    } catch (error) {
      
      toast.error("User is not an Admin");
      setLoader(false);
    }
  };

  const handleSendOtp = async (e) => {
  
    e.preventDefault();
  
    // Check if the username (email) is provided
    if (!username) {
      toast.error("Please enter a valid email address.");
      return;
    }
  
    // Start loading state (can show a loading spinner or message)
    setLoader(true);
  
    const body = { loginId: username }; // Send the email in the request body
  
    // Setup API headers
    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_KEY_DEV
          : process.env.REACT_APP_API_KEY_PROD,
    };
  
    // API endpoint based on the environment
    const url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV + "generateOtp"
        : process.env.REACT_APP_API_BASE_URL_PROD + "generateOtp";
    try {
      // Sending request to backend to generate OTP
      const response = await axios.post(url, body, { headers });
  
      if (response.data.status === 200  && response.data.message === "OTP sent successfully"
      ) {
        // OTP sent successfully, change state to show OTP form
        setIsLoginView(false); // Switch to OTP input view
        setTimer(60); // Reset the timer to 30 seconds
        setIsResendDisabled(true); // Disable the resend OTP button initially
        setIsOtpSent(true); // Mark OTP as sent
  
        toast.success("OTP sent successfully to your email.");
  
      } else {
        // In case of failure (e.g., email not found)
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handle any network or API errors
      toast.error("Incorrect email-id ! Please enter your registered email id.");
    } finally {
      // Stop loader once the request is finished
      setLoader(false);
    }
  };
  

  const handleResendOtp = (e) => {
    // If timer has expired, allow user to resend OTP
    if (timer === 0) {
      handleSendOtp(e); // Call the same function as sending OTP
    }
  };

  return (
    <>
    <ToastContainer />

      <div className="login-main">
        <div className="login-heading">
          <img width={70} src={w2h} alt="w2h_logo" />
            W2H Connect Platform
      </div>

      {loader && (
        <div style={{ display: "flex" }} id="main-alert-box">
          <span className="loader"></span>
        </div>)
      }

      <div className="data">
        <div className="mob-login-logo">
          <img
            width={60}
            src={logo}
            alt="index-logo"
            style={{ paddingTop: "5px" }}
          />
          <h1 className="mob-login-title">INDEX</h1>
          <h3
            className="text-mob"
            style={{ marginTop: "0", marginBottom: "0" }}
          >
            Dashboard to monitor vehicle performance
          </h3>
          <h3 className="text-mob" style={{ marginTop: "0" }}>
            using processed data graphs.
          </h3>
        </div>

        <form
          className="sign-in"
          onSubmit={isOtpSent ? handleSubmit : handleSendOtp}
        >
          <h2 className="login-signin">
            {isOtpSent ? "Enter OTP sent to your Email." : "Sign In"}
          </h2>
          <br />

          {!isOtpSent ? (
            <input
              className="login-input"
              type="text"
              name="username"
              value={username}
              placeholder="E-mail"
              onChange={(e) => setusername(e.target.value)}
              autoComplete="off"
              required={true}
            />
          ) : (
            <input
              className="login-input"
              type="text"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              required={true}
            />
          )}

          

          <div className="zoho-login-container">
          <button
            id="send-otp-button"
            type="submit"
            disabled={isOtpSent && !otp} // Disable submit button until OTP is entered
          >
            {isOtpSent ? "Validate" : "Send OTP"}
          </button>
              <div className="signup-options">
                
                </div>
              <div id="login"></div>
            </div>

          {isOtpSent && timer > 0 ? (
            <div id="resend-otp-button">
              <p>OTP expires in: {timer}s</p>
            </div>
          ) : (
            <button
              id="resend-otp-button"
              type="button"
              onClick={handleResendOtp}
              disabled={isResendDisabled}
              style={{ visibility: isResendDisabled ? "hidden" : "visible" }}
            >
              Resend OTP
            </button>
          )}
        </form>

        <div className="login-logo">
          <img width={70} src={logo} alt="" />
          <h1 className="login-title" style={{ fontSize: "1.5rem" }}>
            Index
          </h1>
          <p>
            Dashboard to monitor vehicle performance using processed data
            graphs.
          </p>
        </div>
      </div>

      <div className="moptros">
        <div className="vehicle">
          <img src={v1} alt="" />
          <h3>WASP®</h3>
        </div>
        <div className="vehicle">
          <img src={v3} alt="" />
          <h3>SNAIL®</h3>
        </div>
        <div className="vehicle">
          <img src={v4} alt="" />
          <h3>LITE®</h3>
        </div>
        <div className="vehicle">
          <img src={v2} alt="" />
          <h3>MoptroB®</h3>
        </div>
      </div>

      <Footer />
    </div>
    </>
  );
};

export default Body;
