import React, { useState, useEffect } from "react";
import Select from "react-select";
import fetchApiData from "../../service/apiUtils"; // Import the API utility

// This component is for the vin dropdown for the vehicle Health 
const VinSelector = ({ currentFlow, handleVinSelect, vinSelected }) => {
  const [vinList, setVinList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const vinCustomStyles  = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "#FFF",
      borderRadius: "20px",
      width: "100%",
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#fff" : "#333",
      color: "#ffff",
    }),
  }
  useEffect(() => {
    const fetchVins = async () => {
      setIsLoading(true);
      try {
        const response = await fetchApiData("/vehicles"); // API call to fetch VINs
        if (response.status === 200) {
          setVinList(response.vins || []);
        } else {
          console.log("Oops!🤷‍♂️ VIN not found.");
        }
      } catch (error) {
        console.log("Oops! 🤷‍♂️ Error fetching VINs.");
      } finally {
        setIsLoading(false);
      }
    };

    if (currentFlow === "Vehicle Health") {
      fetchVins();
    }
  }, [currentFlow]);

  return (
    <>
      {vinList.length > 0 && currentFlow === "Vehicle Health" && (
        <div className="vinDropdown">
          <Select
            styles={vinCustomStyles}
            options={vinList.map((vin) => ({ value: vin, label: vin }))}
            onChange={(selectedOption) => {
              handleVinSelect({ target: { value: selectedOption.value } });
              setVinList([]); // Clear VINs after selection
            }}
            value={vinSelected ? { value: vinSelected, label: vinSelected } : null}
            placeholder="Search or select a VIN..."
            isSearchable
          />
        </div>
      )}
    </>
  );
};

export default VinSelector;
