import React, { useEffect, useState } from "react";
import Select from "react-select";
import fetchApiData from "../../service/apiUtils"; // API utility function

// This component is for the vin dropdown for the customer details

const VinDropdown = ({ customerLocation, onSelectVin }) => {
  const [vins, setVins] = useState([]);
  const [selectedVin, setSelectedVin] = useState(null);
  const vinCustomStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      color: "#FFF",
      borderRadius: "20px",
      width: "20%",
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
      width: "100%",
      color: "white",
      maxHeight: "200px", // Enables scrolling if needed
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#fff" : "#333",
      color: "#ffff",
    }),
  }
   useEffect(() => {
    if (!customerLocation) return; // Only fetch if location is selected

    // Fetch VINs based on the selected customer location
    fetchApiData(`/vin?customerLocation=${customerLocation}`)
      .then((response) => {
        if (response.status === 200) {
          setVins(response.vins);
        }
      })
      .catch((error) => {
        console.error("Error fetching VINs:", error);
      });
  }, [customerLocation]);

  return (
    <div className="customerDropdown">
      <Select
        styles={vinCustomStyles}
        options={vins.map((vin) => ({
          value: vin,
          label: vin,
        }))}
        onChange={(selectedOption) => {
          setSelectedVin(selectedOption);
          onSelectVin(selectedOption.value); // Pass selected VIN to parent
        }}
        value={selectedVin}
        placeholder="Select a VIN..."
        isSearchable
        isDisabled={!customerLocation} // Disable dropdown if no location selected
        menuPlacement="auto" // Prevents unnecessary page scrolling
        menuPortalTarget={document.body} // Prevents dropdown clipping issues
      />
    </div>
  );
};

export default VinDropdown;
