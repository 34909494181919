import React from "react";

const FGQCObservationsTable = ({ observations, selectedVin }) => {
  return (
    <table className="observationTable">
      <thead>
        <tr>
          <td colSpan="2" className="fgqc-heading">
            <b>FGQC Observations for vehicle :</b> {selectedVin}
          </td>
        </tr>
        <tr>
          <th>SL.No.</th>
          <th className="thObservations">Observations</th>
        </tr>
      </thead>
      <tbody>
        {observations.map((observation, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td className="observationColumn">{observation}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FGQCObservationsTable;