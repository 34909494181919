import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import "./accesslogs.css";
import { Select } from "antd";
import moment from "moment";

const AccessLogBody = () => {
  const [loader, setLoader] = useState(true);
  const [accessLogs, setAccessLogs] = useState([]);
  const [selectedDate, setSelectedDate] = useState("today");
  const [selectedAction, setSelectedAction] = useState("all");
  const [unavailableData, setUnavailableData] = useState(false);

  const fetchAccessLogsData = async () => {
    setLoader(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        ZCFKEY:
          process.env.REACT_APP_ENV === "DEVELOPMENT"
            ? process.env.REACT_APP_API_KEY_INDEX_DEV
            : process.env.REACT_APP_API_KEY_INDEX_PROD,
      };

      const url =
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_BASE_URL_INDEX_DEV + "accesslogs"
          : process.env.REACT_APP_API_BASE_URL_INDEX_PROD + "accesslogs";

      const body = {};
      
      body.filter = selectedDate

      const response = await axios.post(url, body, { headers });


      if (response.data.status === 200 && response.data.data.length > 0) {
        response.data.data.sort((a, b) => {    //.sort() mutates the array
          const timeA = new Date(a.login_activity_logs?.login_time || a.logout_activity_logs?.logout_time).getTime();
          const timeB = new Date(b.login_activity_logs?.login_time || b.logout_activity_logs?.logout_time).getTime();
          return timeB - timeA; // Descending order
      });
        const formattedLogs = response.data.data
          .map((entry) => {
            const log = entry.login_activity_logs || entry.logout_activity_logs;
            if (!log) return null;

            let formattedDate, activity;
            if (entry.login_activity_logs) {
              formattedDate = moment(log.login_time, "YYYY-MM-DD HH:mm:ss:SSS").format("Do MMM, YYYY | h:mm A");
              activity = "login";
            } else if (entry.logout_activity_logs) {
              formattedDate = moment(log.logout_time, "YYYY-MM-DD HH:mm:ss:SSS").format("Do MMM, YYYY | h:mm A");
              activity = "logout";
            } else {
              return null;
            }

            // Apply Action Filter
            if (selectedAction !== "all" && selectedAction !== activity) return null;

            return {
              username: log.username,
              timestamp: formattedDate,
              device_info: `${log.device_os} | ${log.device_browser}`,
              activity,
              description: log.description,
            };
          })
          .filter(Boolean); // Remove null/undefined entries

        setAccessLogs(formattedLogs);
        setUnavailableData(formattedLogs.length === 0);
      } else {
        setAccessLogs([]);
        setUnavailableData(true);
      }
    } catch (error) {
      console.error("Error fetching access logs:", error);
      setUnavailableData(true);
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchAccessLogsData();
  }, [selectedDate, selectedAction]);

  return (
    <>
      {loader && <Loader />}
      <div className="access-logbody-main">
        <div className="access-search-bar" style={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "flex-start" }}>
          <p style={{ color: "lightgrey", fontFamily: "Montserrat, sans-serif", fontSize: "19px", margin: 0, paddingLeft: "50px" }}>
            Filter
          </p>
          <label htmlFor="access-options" style={{ color: "white", fontFamily: "Montserrat, sans-serif", marginLeft: "60px" }}>
            Choose Date
          </label>
          <Select
            id="access-options"
            style={{ width: "20%", minWidth: "150px" }}
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(value);
            }}
            options={[
              { value: "today", label: "Today" },
              { value: "yesterday", label: "Yesterday" },
              { value: "7days", label: "Last 7 days" },
              { value: "30days", label: "Last 30 days" },
            ]}
          />

          <label htmlFor="action-options" style={{ color: "white", fontFamily: "Montserrat, sans-serif", marginLeft: "60px" }}>
            Choose Activity
          </label>
          <Select
            id="action-options"
            style={{ width: "20%", minWidth: "150px" }}
            value={selectedAction}
            onChange={setSelectedAction}
            options={[
              { value: "all", label: "All" },
              { value: "login", label: "Login" },
              { value: "logout", label: "Logout" },
            ]}
          />
        </div>

        <div className="access-log-form-body">
        <div id="fixed_heading" className="access-logs-heading">
                <div className="nowrap-text" ></div>
                <div className="nowrap-text">Username</div>
                <div className="nowrap-text">Timestamp</div>
                <div className="nowrap-text">Device Info</div>
                <div className="nowrap-text">Activity</div>
                <div className="nowrap-text">Description</div>
                
        </div>
          {unavailableData ? (
            <div className="logs-dashboard-main">
              <label className="dash-heading">NO DATA AVAILABLE</label>
            </div>
          ) : (
            <table className="scrollable">
              <tbody className="nowrap-text">
                {accessLogs.map((log, index) => (
                  <tr key={index}>
                    <td style={{ width:"14rem"}}>{log.username}</td>
                    <td>{log.timestamp}</td>
                    <td>{log.device_info}</td>
                    <td>{log.activity}</td>
                    <td>{log.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default AccessLogBody;
