import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./sidenavbar.css";

const SideBody = (props) => {
  const [vehicleOpen, setVehicleOpen] = useState(!props.enable ? false : true);
  const [vehicleClass, setVehicleClass] = useState(
    props.class ? "main-home" : "a"
  );

  const handleClick = () => {
    if (!props.enable) {
      setVehicleOpen(!vehicleOpen);
      setVehicleClass(!vehicleOpen ? "main-home" : "a");
    } 
  };

  
  if (props.subheading) {
    return (
      <>
       <NavLink to={props.path}>
        <li id="list_item" onClick={handleClick} className={props.class ? "main-home" : "a"}>
          {props.img && (
            <img className="icon-navs" src={props.img} width="25" alt="" />
          )}
          <h3>{props.name}</h3>
        </li>
      </NavLink>
        {vehicleOpen && (
          <div className="vehicle-item">
            {props.subheading.map((sub, idx) => {
              return (
                <NavLink key={idx} to={sub.path}>
                  <div className="sub-headings">
                    {sub.img && (
                      <img
                        className="icon-navs"
                        src={sub.img}
                        width="22"
                        alt=""
                      />
                    )}
                    <h4 style={{ color: sub.class ? "#F7931D" : "white" }}>
                      {sub.name}
                    </h4>
                  </div>
                </NavLink>
              );
            })}
          </div>
        )}
      </>
    );
  }
  else{
  return (
    <>
      <NavLink to={props.path}>
        <li id="list_item" onClick={handleClick} className={props.class ? "main-home" : "a"}>
          {props.img && (
            <img className="icon-navs" src={props.img} width="25" alt="" />
          )}
          <h3>{props.name}</h3>
        </li>
      </NavLink>
    </>
  );
};
}
export default SideBody;