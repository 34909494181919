import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    emailId : null,
}

const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setEmailId:(state , action)=>{
            state.emailId = action.payload;
        }
    }
})
export const {setEmailId} = userSlice.actions;
export default userSlice.reducer;