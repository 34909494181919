
const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black", // Background color
      color: "white", // Ensure text inside the dropdown is white
    }),
    input: (provided) => ({
      ...provided,
      color: "white", // Ensure typed text is white
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Ensure selected value is white
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black", // Dropdown menu background color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "black", // Highlight selected option
      color: "white", // Ensure dropdown text is white
      "&:hover": {
        backgroundColor: "gray", // Hover effect
      },
    }),
  };

  export default customStyles;