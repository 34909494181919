import React, { useState } from 'react';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';

const EditableTable = ({ finalObservations, editObsVinSelected , setChatHistory}) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedObservation, setEditedObservation] = useState('');
  const [observations, setObservations] = useState([...finalObservations]);
  const [editArray , setEditArray] = useState([])
    const [loader, setLoader] = useState(false);
  // Handle the click to edit an observation
  const handleEditClick = (index, observation) => {
    setEditingIndex(index);
    setEditedObservation(observation);
  };

  // Handle input change for editing
  const handleObservationInputChange = (e) => {
    
    if(e.target.value === undefined){
      return
    }
    setEditedObservation(e.target.value);
  };
  const handleFinalSubmit = async (e) =>{
    setChatHistory((prev) => [...prev,{ sender: "user", text: "Yes , Submit !" }]);

    try {
      setLoader(true);
      const body = { vin:editObsVinSelected , observations:editArray  };
      
      const headers = {
          "Content-Type": "application/json",
          ZCFKEY: process.env.REACT_APP_ENV === "DEVELOPMENT"
              ? process.env.REACT_APP_API_KEY_DEV
              : process.env.REACT_APP_API_KEY_PROD,
      };
      const url = process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_BASE_URL_DEV + "editAllObservations"
          : process.env.REACT_APP_API_BASE_URL_PROD + "editAllObservations";

      const response = await axios.post(url, body, { headers });
      

      if (response.status === 201) {
        setChatHistory((prev) => [...prev, { sender: "system", text:"Your Observation List is successfully updated ✅ !" }]);
        setChatHistory((prev) => [...prev, { sender: "system", text:"Check your updated List in Show Observations!" }]);

   
        
      } else {
          throw new Error("Failed to add observations");
      }
  } catch (error) {

     if(error.status === 400){
      setChatHistory((prev) => [...prev, { sender: "system", text: "No Observations were edited !" }]);
      
     }
     else{
      setChatHistory((prev) => [...prev, { sender: "system", text: "Error adding observations: " + error.message }]);

     }
  }

    finally
    {
        setLoader(false);
        setChatHistory((prev) => [...prev,{ sender: "system", text: "Reset Chat to go back to Menu!👋😊" }]);
    }
    
  }
  // Updating the observation in the observations list to be displayed.
  const handleObservationEdit = (index, newObservation) => {
    setObservations((prevObservations) =>
      prevObservations.map((obs, i) => (i === index ? newObservation : obs))
    );
  };

  // Handle submission of edited observation
  const handleSubmitObservations = () => {
    if (editingIndex !== null && editedObservation.trim() !== '') {
      handleObservationEdit(editingIndex, editedObservation);
      setEditArray((prev) => {
      //copy of actual array of editted observations
      let updatedArray = [...prev];

      // Checking if there's already an observation at editingIndex
      const existingIndex = updatedArray.findIndex(item => item.editingIndex === editingIndex);

      if (existingIndex !== -1) {
        // If exists and is different, update it
        if (updatedArray[existingIndex].editedObservation === editedObservation) {
          updatedArray = [...prev];
        }
        else{
         updatedArray[existingIndex] = { editingIndex, editedObservation };
        }
      } else {
        // Otherwise, add a new observation
        
        if (finalObservations[editingIndex] === editedObservation) {
          
        }
        else{
          updatedArray.push({ editingIndex, editedObservation });
        }
      }

      return updatedArray;
    });
  
      // Reset input fields
      setEditingIndex(null);
      setEditedObservation('');
    }
  };
  
  
  // Handle "Enter" key down
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmitObservations();
    }
  };

  return (
    
    <table className="EditableTable">
      {loader && <Loader />}
      <tbody>
        <tr>
          <td colSpan="3" className="fgqc-heading">
            <b>Edit existing observations for {editObsVinSelected} below!</b>
          </td>
        </tr>
        <tr>
          <th>SL.No.</th>
          <th className="thObservations">Observations</th>
          <th>Action</th>
        </tr>
        {observations.map((observation, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td className="observationColumn">
              {editingIndex === index ? (
                <input
                  className='editInput'
                  type="text"
                  value={editedObservation}
                  onChange={handleObservationInputChange}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              ) : (
                observation
              )}
            </td>
            <td className='editButtons'>
              {editingIndex === index ? (
                <button className="write-submit-btn" onClick={handleSubmitObservations}>
                  Confirm
                </button>
              ) : (
                <button className="write-submit-btn" onClick={() => handleEditClick(index, observation)}>
                  Edit
                </button>
              )}
            </td>
            
          </tr>
        
        ))}
       <tr>
          <td colSpan="3">
           <b></b>
           Want to Submit ? Click here ! <button className="write-submit-btn" onClick={handleFinalSubmit}>
              Submit
            </button>
          </td>
          
        </tr>
        
      </tbody>
    </table>
  );
};

export default EditableTable;
